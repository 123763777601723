import React from 'react';


const ComingSoon = ({autoHeight}) => {
  return (
    <div className={`coming-soon-section ${autoHeight ? '' : 'content-full-height'}`}>
      <span>work in progress...✌️</span>
    </div>
  );
};

export default ComingSoon;
